import React, { ReactElement, useState } from 'react';

import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ELearningBanner } from './ELearningBanner';
import { ELearningCategory } from './ELearningCategory';
import { ELearningProfile } from './ELearningProfile';
import { ELearningMostViewed } from './ELearningMostViewed';
import { ELearningStarted } from './ELearningStarted';
import { CategoryTypeEnum } from '../../../enum/CategoryTypeEnum';
import { ResourceDataProviderEnum } from '../../../enum/ResourceDataProviderEnum';
import { CategoryDto } from '../../../model/dto/CategoryDto';
import ListingWebinaire from '../../../views/webinair/ListingWebinaire';
import { BaseContainer } from '../../layout/BaseContainer';
import { ELearningDto } from '../../../model/dto/ELearningDto';
import { Dropdown } from '../../dropdown/Dropdown';
import { CategoryDropdown } from '../../dropdown/CategoryDropdown';


export function ELearningPage(): ReactElement {

  const [categoriesNavigatorOpened, setCategoriesNavigatorOpened] = useState(false);

  // on va récupérer lal iste de mes catégories pour le type elearning
  const dsCategories = useDataSource<CategoryDto>({
    resource: ResourceDataProviderEnum.category,
    params: {
      filter: { type: CategoryTypeEnum['e-learning'] },
      sort: { field: 'categoryOrder', order: 'asc' },
    },
  });

  // on va récupérer la liste de mes formations les plus vues
  const dsMostViewedFormations = useDataSource<ELearningDto>({
    resource: ResourceDataProviderEnum.eLearningMostViewed,
    params: {
      filter: { light: true },
    },
  });

  // on va récupérer la liste de mes formations dans une thématique donnée
  const dsStartedFormations = useDataSource<ELearningDto>({
    resource: ResourceDataProviderEnum.eLearningStarted,
    params: {
      filter: { light: true },
    },
  });

  // on va récupérer la liste des formations pour mon profil
  const dsMyProfileFormations = useDataSource<ELearningDto>({
    resource: ResourceDataProviderEnum.eLearningMyProfile,
    params: {
      pagination: { page: 1, take: 10 },
    },
  });

  return (
    <BaseContainer withBotInput>
      <>
        <ELearningBanner />
        <div className="mb-4">
          <ListingWebinaire />
        </div>
        <div id="most-viewed">
          <ELearningMostViewed />
        </div>
        <div id="en-cours">
          <ELearningStarted />
        </div>
        <div id="my-profile">
          <ELearningProfile />
        </div>
        {dsCategories.dataList.map((category : CategoryDto, index : number) => (
          <div id={`category-${index}`}>
            <ELearningCategory category={category} key={category.id} />
          </div>
        ))}

        <div className={(categoriesNavigatorOpened) ? "categories-navigator opened" : "categories-navigator"}>
          <div className="head">
            <button onClick={() => setCategoriesNavigatorOpened(!categoriesNavigatorOpened)}>
              <i className="uil uil-apps" />
              <i className="uil uil-multiply" />
              Toutes les catégories
            </button>
          </div>
          <div className="body">
            <ul>
              {dsMostViewedFormations.total > 0 && <Dropdown href="#most-viewed" label="Top 10 formations" ds={dsMostViewedFormations} />}
              {dsStartedFormations.total > 0 && <Dropdown href="#en-cours" label="Mes formations en cours" ds={dsStartedFormations} />}
              {dsMyProfileFormations.total > 0 && <Dropdown href="#my-profile" label="Pour votre profil" ds={dsMyProfileFormations} />}
              {dsCategories.dataList.map((category: CategoryDto, index : number) => (
                <CategoryDropdown index={index} category={category} resource={ResourceDataProviderEnum.eLearning}/>
              ))}
            </ul>
          </div>
        </div>

      </>
    </BaseContainer>
  );
}
