import React, { useState } from 'react';

import axios from 'axios';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import Modal from 'react-bootstrap/Modal';
import OwlCarousel from 'react-owl-carousel';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Elearning from '../../assets/icons/E-learning.svg';
import Webinar from '../../assets/icons/Micro2 1.svg';
import Presentiel from '../../assets/icons/presentiel.svg';
import { ResourceDataProviderEnum } from '../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../enum/RouteEnum';
import Hn from '../common/Hn';
import PopupSubscribe from '../popup/PopupSubscribe';
import PopupDemo from '../popup/PopupDemo';

export default function Banner({
  data, url, from, withIASlide = false,
}) {
  const uri = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const [showMessageModal, setShowMessageModal] = useState(false);
  const [id, setId] = useState([]);
  const [showModalInscri, setShowModalInscri] = useState(false);
  const [webinarsTitle, setWebinarsTitle] = useState('...');
  const [showfiltreSubscribe, setShowfiltreSubscribe] = useState(false);
  const { authData } = useAuthProvider();
  const config = useSelector((c) => c.config);
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  // ajouter un participant ou plus en checkant un de la liste affichée à un webinair déja crée
  function inscrire() {
    const dataParticipant = {
      idEntity: id,
    };

    axios.post(`${uri}/${ResourceDataProviderEnum.participantWebinar}`, dataParticipant)
      .then(() => {
        hideModal();
        setTimeout(() => {
          navigate(0);
        }, 100);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  const options = {
    items: 1,
    responsiveClass: true,
    margin: 27,
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    loop: true,
  };

  const hideModal = () => {
    setShowModalInscri(false);
  };

  const closefiltreSubscribe = () => {
    setShowfiltreSubscribe(false);
  };

  const onShowMessage = () => {
    setShowMessageModal(true);
  }

  const onHideMessage = () => {
    setShowMessageModal(false);
  }

  /**
   * Ouverture de la page chatbot sans message
   */
  const openChatBot = () => {
    const text = ""
    navigate(RouteEnum.BOT, { state: { text } });
  }

  return (
    <div className="banner">
      <PopupSubscribe
        showFilterSubscribe={showfiltreSubscribe}
        setShowFilterSubscribe={setShowfiltreSubscribe}
      />

      <OwlCarousel className="owl-theme" {...options}>
        {(withIASlide) && (
          <div className="banner-slide banner-slide-ia" key={0}>
            <div className="content">
              <h2 className="mb-3">Guy-A, le moteur de recherche intelligent</h2>
              <button className="btn btn-primary" onClick={openChatBot}>Lancer le moteur de recherche</button>
            </div>
          </div>
        )}

        {data?.map((item) => (
          <div className="banner-slide" key={item.id}>
            <div className="content">
              <h2>{item?.title || item?.intitule}</h2>
              <span className="text-white">{item?.theme?.name}</span>
            </div>
            <div className="d-flex align-items-center gap-3">
              {from === 'presentiel' && item?.type_icone !== 'webinar' ? (
                item?.forward_catalog === true ? (
                  item?.sessions?.filter((obj) => obj.id === localStorage.getItem('id')).length !== 0 ? (null) : (null)
                ) : (
                  <a
                    onClick={() => {
                      if (elearning === 'non') {
                        setShowfiltreSubscribe('true');
                      } else {
                        navigate(
                          `${url}/detailformations/${item?.id}?run`,
                        );
                      }
                    }}
                    className="btn btn-primary"
                  >
                    {item.label}
                  </a>
                )
              ) : item?.forward_catalog === true ? (
                item?.contacts.some((obj) => (obj.contact.id === authData.id)) ? (
                  <RouterLink className="btn btn-primary" routeName={RouteEnum.WEBINAR_DETAIL} params={{ id: item.id }}>
                    Détail
                  </RouterLink>
                ) : (
                  <>
                    <button
                      role="button"
                      title="S'inscrire "
                      className="btn btn-primary"
                      onClick={() => {
                        setId(item.id);
                        setWebinarsTitle(item.title);
                        setShowModalInscri(true);
                      }}
                    >
                      Inscrire
                    </button>
                    <RouterLink rel="opener" className="btn btn-outline-primary" routeName={RouteEnum.WEBINAR_DETAIL} params={{ id: item.id }}>
                      En savoir plus
                    </RouterLink>
                  </>
                )
              ) : (
                <button
                  role="button"
                  onClick={() => {
                    if (config.accessELearning) {
                      if (!item?.demo && config.demo) {
                        // Menu elearning - mode demo + pas de droit d'accès
                        onShowMessage();
                      }
                      else{
                        // Menu elearning, cas général
                        navigate(`/${url}/${item?.id}?run`);
                      }
                    } else {
                      setShowfiltreSubscribe('true');
                    }
                  }}
                  title="en savoir plus"
                  className={"btn btn-primary " + ((!item?.demo && config.demo) ? "disabled-demo" : "")}
                >
                  {item.label}
                </button>
              )}

              {from === 'elearning' && (
                <>
                {
                  // Menu elearning, cas général
                  (item?.demo || !config.demo) &&
                  <RouterLink routeName={RouteEnum.E_LEARNING_DETAIL} params={{ id: item.id }} className="btn btn-outline-light">
                    En savoir plus
                  </RouterLink>
                }
                {
                  // Menu elearning - mode demo + pas de droit d'accès
                  (!item?.demo && config.demo) &&
                  <button
                    role="button"
                    onClick={onShowMessage}
                    title="en savoir plus"
                    className={"btn btn-outline-light disabled-demo"}
                  >
                    En savoir plus
                  </button>
                }
                </>
              )}
              {(!from || (from !== 'elearning' && from !== 'webinar')) && (
                <RouterLink routeName={RouteEnum.PRESENTIEL_DETAIL} params={{ id: item.id }} className="btn btn-outline-light">
                  En savoir plus
                </RouterLink>
              )}
            </div>
            <div className="illu">
              {from === 'presentiel' ? (
                <img src={item.type_icone === 'webinar' ? Webinar : Presentiel} alt=""/>
              ) : (
                <img src={item.forward_catalog === true ? Webinar : Elearning} alt="" />
              )}
            </div>
          </div>
        ))}
      </OwlCarousel>
      <Modal
        show={showModalInscri}
        onHide={() => hideModal()}
        size="lg"
        className="special-width-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title className="px-3 nopad">
            <Hn title="Confirmation d'inscription" level="h3" plus={false} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3 nopad">
            <div className="col-md-12">
              <Hn
                title="Voulez vous confirmez votre inscription au nom de :"
                level="h5"
                plus={false}
                className="label-popup"
              />
              <p className="text-white textBox">
                {`${authData.firstname} ${authData.lastname}`}
              </p>
            </div>
            <div className="pt-2">
              <Hn
                title="Webinaire :"
                level="h5"
                plus={false}
                className="label-popup"
              />
              <p className="text-white textBox">
                {webinarsTitle}
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary float-end" onClick={() => { hideModal(); inscrire(); }}>
            Confirmer
          </button>
        </Modal.Footer>
      </Modal>
      <PopupDemo
        show={showMessageModal}
        onHide={onHideMessage}
        type="module"
      />
    </div>
  );
}
