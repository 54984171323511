import React, { ReactElement } from 'react';
import Iframe from 'react-iframe';
import { useLocation } from 'react-router-dom';

import { BaseContainer } from '../../layout/BaseContainer';

export function BotPage(): ReactElement {

  // Réception du texte entré par l'utilisateur
  const location = useLocation();
  const { text } = location.state;

  // Préparation de la requête
  const url = process.env.REACT_APP_CHATBOT
            + ( text ? '?request=' + encodeURIComponent(text) + '&embed=true' : '?embed=true' );

  return (
    <BaseContainer>
      <Iframe
        url={url}
        className="ia-bot"
      ></Iframe>
    </BaseContainer>
  );
}

