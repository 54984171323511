import * as Yup from 'yup';

import React, { useEffect, useRef, useState } from 'react';

import axios from 'axios';
import { ErrorMessage, Formik } from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { Button, Modal, ProgressBar, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAuthUrl } from '../../../../../hooks/useAuthUrl';
import { EditUnite } from '../../../../../components/pages/admin/eLearning/EditUnite';

import Add from '../../../../../assets/icons/add.png';
import edit from '../../../../../assets/icons/edit.png';

import './createUnite.css';

const validationSchema = Yup.object().shape({
  titre: Yup.string().required('Le titre est obligatoire'),
  document: Yup.mixed().nullable(),
});

function EditUniteDeFormation({ uniteToBeEdited }) {
  const [showModal, setShowModal] = useState(false);
  const uri = process.env.REACT_APP_BASE_URL;
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const fileRef = useRef();
  const [duree, setDuree] = useState('0');
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };
  const { loading, openAuthUrl } = useAuthUrl();

  const onHandelStatus = (touched, error, value) => {
    if (error && touched) {
      return 'danger';
    } if (value) {
      return 'info';
    }
    return 'warning';
  };

  const showModalFunction = (unite) => {
    setShowModal(unite);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const handleSubmit = (values) => {
    // upload
    const options = {
      onUploadProgress(progressEvent) {
        const { loaded, total } = progressEvent;

        const percent = Math.floor((loaded * 100) / total);

        if (percent < 100) {
          setUploadPercentage(percent);
        }
      },
    };

    const data = new FormData();
    data.append('titre', values.titre);
    data.append('transcript', values.transcript);

    if (values.document != null) {
      data.append('document', values.document);
      data.append('duree', duree);
    }

    axios.post(
      `${uri}/unite/${uniteToBeEdited?.id}/edit`,
      data,
    )
      .then(() => {
        setUploadPercentage(100);

        setTimeout(() => {
          setUploadPercentage(0);
        }, 1000);

        values.titre = '';
        values.transcript = '';
        values.document = null;
        document.getElementById('formulaire-edit-unite').reset();
        toast.success('Cette unité a bien été modifiée!');
        window.location.reload();
      })
      .catch((err) => {
        toast.error('Action non autorisée!');
        console.log('err training', err);
        setUploadPercentage(0);
      });
  };

  function getDuration(control) {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      const duration = new Date(video.duration * 1000).toISOString().substring(11, 19);
      setDuree(duration);
    };
    video.src = URL.createObjectURL(control.files[0]);
  }

  useEffect(() => {

  }, [uniteToBeEdited]);

  return (
    <>
      <span className="cursor-pointer" onClick={(e) => showModalFunction(uniteToBeEdited)}><img src={edit} className="pe-1" /></span>
      <Modal show={showModal} onHide={(e) => hideModal()} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            Modification de l'unité de formation "
            {uniteToBeEdited?.titre}
            "
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row g-3">
            <Formik
              initialValues={{
                titre: uniteToBeEdited?.titre,
                transcript: uniteToBeEdited?.transcript,
                document: null,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <form className="row g-3" id="formulaire-edit-unite" onSubmit={handleSubmit}>

                  <div className="col-md-6">
                    <EditUnite values={values} handleChange={handleChange} handleBlur={handleBlur} />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">
                      Document
                      {' '}
                      <button
                        onClick={(e)=>openAuthUrl(e,uri + '/uploads/unite/' + uniteToBeEdited?.id + '/document')}
                        className="pe-1 btn btn-link"
                      >
                        Télécharger
                      </button>
                      {loading && <Spinner animation="border" size="sm" />}
                    </label>
                    <span
                      className="form-control"
                      onClick={() => fileRef.current.click()}
                    >

                      <img src={Add} className="pe-1" />
                      {' '}
                      {fileRef?.current?.value ? fileRef?.current?.value : 'Ajouter un fichier'}
                    </span>

                    <input
                      ref={fileRef}
                      multiple={false}
                      type="file"
                      name="document"
                      id="document"
                      onChange={(event) => {
                        setFieldValue('document', event.currentTarget.files[0]);
                        getDuration(event.currentTarget);
                      }}
                      hidden
                      accept=".mp4,.zip,.pdf,.mp3,.webm"
                    />
                    {uploadPercentage > 0 && (
                      <div className="row mt-3">
                        <div className="col pt-1">
                          <ProgressBar
                            now={uploadPercentage}
                            striped
                            label={`${uploadPercentage}%`}
                          />
                        </div>
                      </div>
                    )}
                    <ErrorMessage name="document">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                    <p> Format : mp4, zip, pdf, mp3, webm </p>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Button variant="" onClick={(e) => hideModal()} style={{ color: '#006FFF' }}>
                        Annuler
                      </Button>
                    </div>

                    <div className="col-md-6 text-right">
                      <Button
                        type="submit"
                        variant="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>

                </form>
              )}
            </Formik>

          </div>
        </Modal.Body>

      </Modal>

    </>
  );
}

export default EditUniteDeFormation;
