import React, { ReactElement, useState } from 'react';

import { Icon } from 'leeloo-react/component/display/Icon';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useWriteReducer } from 'leeloo-react/component/hook/useWriteRedux';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import { Nav } from 'react-bootstrap';

import { MenuLink } from './MenuLink';
import { ReactComponent as Gh } from '../../assets/icons/Gh.svg';
import RoleUserEnum from '../../enum/RoleUserEnum';
import { RouteEnum } from '../../enum/RouteEnum';
import { ReducerEnum } from '../../enum/store/ReducerEnum';
import { AuthReducerType } from '../../model/authProvider/AuthReducerType';
import { ConfigReducerType } from '../../model/store/reducers/ConfigReducerType';
import { LogoutAsLink } from '../auth/logout/LogoutAsLink';
import { LogoutLink } from '../auth/logout/LogoutLink';
import {BotInput} from "../form/BotInput";

const customMenu = [
  {
    label: 'Formations e-learning',
    icon: 'uil uil-desktop',
    routes: [
      RouteEnum.ADMIN_E_LEARNING,
      RouteEnum.ADMIN_E_LEARNING_LEARNER,
    ],
  },
  {
    label: 'Formations présentielles',
    icon: 'uil uil-book-alt',
    routes: [
      RouteEnum.ADMIN_TRAINING_REQUEST,
      RouteEnum.ADMIN_ON_SITE,
      RouteEnum.ADMIN_SESSION,
      RouteEnum.ADMIN_ON_SITE_PARTICIPANT,
      RouteEnum.ADMIN_TRAINER,
      RouteEnum.ADMIN_PROVIDER,
      RouteEnum.ADMIN_TRAINING_PLACE,
    ],
  },
  {
    label: 'Thématiques',
    icon: 'uil uil-th',
    routes: [
      RouteEnum.ADMIN_CATEGORY,
      RouteEnum.ADMIN_THEMATIC,
    ],
  },
  {
    label: 'Compétences',
    icon: 'uil uil-star',
    routes: [RouteEnum.ADMIN_SKILL],
  },
  {
    label: 'Webinaires',
    icon: 'uil uil-video',
    routes: [RouteEnum.ADMIN_WEBINAR],
  },
  {
    label: 'Utilisateurs',
    icon: 'uil uil-users-alt',
    routes: [RouteEnum.ADMIN_USERS],
  },
  {
    label: 'Paramètres',
    icon: 'uil uil-setting',
    routes: [RouteEnum.ADMIN_SETTINGS],
  },
];

export function AdminSidebar(): ReactElement {
  const { checkAccess, authData }: {checkAccess: (roles: string[]) => boolean, authData:AuthReducerType} = useAuthProvider()! as any;
  const [activeIndex, setActiveIndex] = useState<number|null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [iaOverlayOpened, setIaOverlayOpened] = useState<boolean>(false);
  const dispatchConfig = useWriteReducer<ConfigReducerType>(ReducerEnum.config);

  return (
    <div className={`sidebar ${open ? 'opened' : ''}`}
         onMouseLeave={() => setActiveIndex(null)}>
      <RouterLink
        routeName={RouteEnum.ADMIN_DASHBOARD}
        className="nav_logo"
      >
        <Gh/>
      </RouterLink>

      <button type="button" className="btn-toggle-ia-overlay" onClick={() => setIaOverlayOpened(true)}>
        <svg fill="none" height="20" viewBox="0 0 22 20" width="22" xmlns="http://www.w3.org/2000/svg">
          <path d="m12.375 1.83337c0 .40723-.177.77311-.4583 1.02488v1.72512h4.5833c1.5188 0 2.75 1.23123 2.75 2.75v9.16663c0 1.5189-1.2312 2.75-2.75 2.75h-11c-1.51878 0-2.75-1.2311-2.75-2.75v-9.16663c0-1.51877 1.23122-2.75 2.75-2.75h4.5833v-1.72512c-.28129-.25177-.4583-.61765-.4583-1.02488 0-.75939.6156-1.374996 1.375-1.374996s1.375.615606 1.375 1.374996zm-6.875 4.58334c-.50626 0-.91667.41041-.91667.91666v9.16663c0 .5063.41041.9167.91667.9167h11c.5063 0 .9167-.4104.9167-.9167v-9.16663c0-.50625-.4104-.91666-.9167-.91666h-4.5833-1.8334zm-3.66667 2.75h-1.83333v5.49999h1.83333zm18.33337 0h1.8333v5.49999h-1.8333zm-11.9167 4.12499c.75939 0 1.375-.6156 1.375-1.375s-.61561-1.375-1.375-1.375-1.375.6156-1.375 1.375.61561 1.375 1.375 1.375zm5.5 0c.7594 0 1.375-.6156 1.375-1.375s-.6156-1.375-1.375-1.375-1.375.6156-1.375 1.375.6156 1.375 1.375 1.375z" fill="#fff"/>
        </svg>
      </button>

      <div className={`ia-overlay ${iaOverlayOpened ? 'opened' : ''}`}>
        <BotInput placeholder="Que cherchez-vous ?" />
        <button type="button" className="btn-close-overlay" onClick={() => setIaOverlayOpened(false)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5859 12L2.79297 4.20706L4.20718 2.79285L12.0001 10.5857L19.793 2.79285L21.2072 4.20706L13.4143 12L21.2072 19.7928L19.793 21.2071L12.0001 13.4142L4.20718 21.2071L2.79297 19.7928L10.5859 12Z" fill="white"/>
          </svg>
        </button>
      </div>

      <div role="button" className="burger" tabIndex={0}
           onClick={() => setOpen(!open)} onKeyUp={() => setOpen(!open)}>
        <span/>
        <span/>
        <span/>
      </div>
      <Nav defaultActiveKey={RouteEnum.ADMIN_DASHBOARD} className="main-nav">
        <MenuLink
          classNameLink="nav-link cursor-pointer"
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          prependRoute={(
            <li>
              <RouterLink icon="uil uil-estate" className="nav-link"
                          routeName={RouteEnum.ADMIN_DASHBOARD}>
                <span>Dashboard</span>
              </RouterLink>
            </li>
          )}
          customMenu={customMenu}
        />

        <div className="bot-input-sidebar-wrapper">
          <BotInput placeholder="Que cherchez-vous ?" />
        </div>

        <ul>
          {checkAccess([RoleUserEnum.ROLE_SUPER_ADMIN]) && (
            <li role="presentation"
                onClick={() => dispatchConfig.set('displayFront', true)}>
              <RouterLink icon="uil uil-user-arrows" className="nav-link"
                          routeName={RouteEnum.E_LEARNING}>
                <span>Plateforme</span>
              </RouterLink>
            </li>
          )}
          <li>
            {authData.loginAs
              ? <LogoutAsLink/>
              : (
                <LogoutLink className="nav-link logout cursor-pointer">
                  <Icon name="uil uil-lock"/>
                  <span>Déconnexion</span>
                </LogoutLink>
              )}
          </li>
        </ul>
      </Nav>

    </div>
  );
}
