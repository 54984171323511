import React from 'react';

import { ErrorMessage } from 'formik';

type EditUniteProps = {
    values: {
        titre?: string,
        transcript?: string
    }
    handleChange : (fieldname : string) => (React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined),
    handleBlur : (fieldname : string) => (React.FocusEventHandler<HTMLInputElement|HTMLTextAreaElement> | undefined)
}

export function EditUnite({ values, handleChange, handleBlur } : EditUniteProps) {

  return (
    <>
      <div className="mb-3">
        <label className="form-label">Titre</label>
        <input
          type="text"
          className="form-control"
          id="titre"
          name="titre"
          placeholder="Ajouter un titre"
          onChange={handleChange('titre')}
          value={values.titre}
          onBlur={handleBlur('titre')}
        />
        <ErrorMessage name="titre">
          {(msg) => <div className="text-danger">{msg}</div>}
        </ErrorMessage>
      </div>
      <div className="mb-3">
        <label className="form-label">Description</label>
        <textarea
          className="form-control"
          id="transcript"
          name="transcript"
          placeholder="Ajouter une description"
          onChange={handleChange('transcript')}
          value={values.transcript}
          onBlur={handleBlur('transcript')}
        />
        <ErrorMessage name="transcript">
          {(msg) => <div className="text-danger">{msg}</div>}
        </ErrorMessage>
      </div>
    </>
  );
}