import { RouteEnum } from "../../enum/RouteEnum";
import React from "react";
import { useNavigate } from "react-router-dom";

interface BotInputProps {
  placeholder?: string;
}

export function BotInput({ placeholder = "Que cherchez-vous ? Posez vos questions à Guy-A, votre assistant intelligent." }: BotInputProps) {
  const navigate = useNavigate();

  const startChatBot = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const formData = new FormData(e.currentTarget);
    const text = formData.get("searchInit") as string;

    if (text) {
      navigate(RouteEnum.BOT, { state: { text } });
    }
  };

  return (
    <form onSubmit={startChatBot} className="bot-input-form">
      <span className="icon">
        <svg fill="none" height="20" viewBox="0 0 22 20" width="22" xmlns="http://www.w3.org/2000/svg">
          <path d="m12.375 1.83337c0 .40723-.177.77311-.4583 1.02488v1.72512h4.5833c1.5188 0 2.75 1.23123 2.75 2.75v9.16663c0 1.5189-1.2312 2.75-2.75 2.75h-11c-1.51878 0-2.75-1.2311-2.75-2.75v-9.16663c0-1.51877 1.23122-2.75 2.75-2.75h4.5833v-1.72512c-.28129-.25177-.4583-.61765-.4583-1.02488 0-.75939.6156-1.374996 1.375-1.374996s1.375.615606 1.375 1.374996zm-6.875 4.58334c-.50626 0-.91667.41041-.91667.91666v9.16663c0 .5063.41041.9167.91667.9167h11c.5063 0 .9167-.4104.9167-.9167v-9.16663c0-.50625-.4104-.91666-.9167-.91666h-4.5833-1.8334zm-3.66667 2.75h-1.83333v5.49999h1.83333zm18.33337 0h1.8333v5.49999h-1.8333zm-11.9167 4.12499c.75939 0 1.375-.6156 1.375-1.375s-.61561-1.375-1.375-1.375-1.375.6156-1.375 1.375.61561 1.375 1.375 1.375zm5.5 0c.7594 0 1.375-.6156 1.375-1.375s-.6156-1.375-1.375-1.375-1.375.6156-1.375 1.375.6156 1.375 1.375 1.375z" fill="#fff"/>
        </svg>
      </span>
      <input
        type="text"
        name="searchInit"
        placeholder={placeholder}
      />
    </form>
  );
}
