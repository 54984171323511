import React, { ReactElement, useState } from 'react';

import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { OnSiteBanner } from './OnSiteBanner';
import { OnSiteCategory } from './OnSiteCategory';
import { OnSiteMostViewed } from './OnSiteMostViewed';
import { CategoryTypeEnum } from '../../../enum/CategoryTypeEnum';
import { ResourceDataProviderEnum } from '../../../enum/ResourceDataProviderEnum';
import { CategoryDto } from '../../../model/dto/CategoryDto';
import ListingWebinaire from '../../../views/webinair/ListingWebinaire';
import { BaseContainer } from '../../layout/BaseContainer';
import { Dropdown } from '../../dropdown/Dropdown';
import {
  CategoryDropdown
} from '../../dropdown/CategoryDropdown';
import { OnSiteDto } from '../../../model/dto/OnSiteDto';

export function OnSitePage(): ReactElement {

  const [categoriesNavigatorOpened, setCategoriesNavigatorOpened] = useState<boolean>(false);

  // on va récupérer la liste de mes catégories pour le type présentiel
  const dsCategories = useDataSource<CategoryDto>({
    resource: ResourceDataProviderEnum.category,
    params: {
      filter: { type: CategoryTypeEnum['Présentiel'] },
      sort: { field: 'categoryOrder', order: 'asc' },
    },
  });

  const dsMostViewedFormations = useDataSource<OnSiteDto>({
    resource: ResourceDataProviderEnum.trainingMostViewed,
    params: {
      filter: { light: true },
    },
  });

  return (
    <BaseContainer withBotInput>
      <>
        <OnSiteBanner />
        <div className="mb-4">
          <ListingWebinaire />
        </div>
        <div id="most-viewed">
          <OnSiteMostViewed />
        </div>
        {dsCategories.dataList.map((category : CategoryDto, index: number) => (
          <div id={`category-${index}`}>
            <OnSiteCategory category={category} key={category.id} />
          </div>
        ))}

        <div className={(categoriesNavigatorOpened) ? "categories-navigator opened" : "categories-navigator"}>
          <div className="head">
            <button onClick={() => setCategoriesNavigatorOpened(!categoriesNavigatorOpened)}>
              <i className="uil uil-apps" />
              <i className="uil uil-multiply" />
              Toutes les catégories
            </button>
          </div>
          <div className="body">
            <ul>
              <Dropdown href="#most-viewed" label="Top 10 formations" ds={dsMostViewedFormations} />
              {dsCategories.dataList.map((category: CategoryDto, index : number) => (
                <CategoryDropdown index={index} category={category} resource={ResourceDataProviderEnum.training}/>
              ))}
            </ul>
          </div>
        </div>

      </>
    </BaseContainer>
  );
}
