import React from 'react';

import { FadeAnim } from 'leeloo-react/component/animation/FadeAnim';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useSelector } from 'react-redux';

import RoleUserEnum from '../../enum/RoleUserEnum';
import { AuthReducerType } from '../../model/authProvider/AuthReducerType';
import { StateType } from '../../model/store/StateType';
import { AdminSidebar } from '../sidebar/AdminSidebar';
import { Sidebar } from '../sidebar/Sidebar';
import ScrollToTop from '../tools/ScrollToTop';
import {BotInput} from "../form/BotInput";

type Props = {
    children?: JSX.Element|JSX.Element[],
    headComponent?: JSX.Element|JSX.Element[],
    footerComponent?: JSX.Element|JSX.Element[],
    wrapperClassName?: string,
    withBotInput?: boolean,
}

/**
 * Containeur de base de toute les pages sécurisé du site
 */
export function BaseContainer(props: Props) {
  const { authData, checkAccess }: {authData: AuthReducerType, checkAccess: (roles: string[]) => boolean} = useAuthProvider()! as any;
  const config = useSelector((s: StateType) => s.config);

  return (
    <>
      <ScrollToTop />
      <div className={`${(props.wrapperClassName) ? props.wrapperClassName : ''} body-wrapper`}>
        {authData && (
          <>
            <aside>
              {checkAccess([RoleUserEnum.ROLE_SUPER_ADMIN]) && !config.displayFront ? (
                <AdminSidebar />
              ) : (
                <Sidebar />
              )}
            </aside>
            <main>
              {props.headComponent}
              <div className="container-fluid">
                {props.withBotInput && (
                  <div className="bot-input-page-wrapper">
                    <BotInput />
                  </div>
                )}
                <FadeAnim duration={0.5}>
                  {props.children}
                </FadeAnim>
              </div>
              {props.footerComponent}
            </main>
          </>
        )}
      </div>
    </>
  );
}

BaseContainer.defaultProps = {
  children: null,
  headComponent: null,
  footerComponent: null,
  wrapperClassName: null,
  withBotInput: false
};
